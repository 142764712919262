import IDCfg from './id';
import MYCfg from './my';
import BRCfg from './br';
import PHCfg from './ph';
import THCfg from './th';
import VNCfg from './vn';

export type CfgType = typeof IDCfg &
  typeof MYCfg &
  typeof BRCfg &
  typeof PHCfg &
  typeof THCfg &
  typeof VNCfg;

const config: CfgType = require(`./${BIZ_COUNTRY}`).default;

export default config;
