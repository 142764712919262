/* eslint-disable import/no-dynamic-require */
import { getIntl, getLocale, addLocale } from 'umi';
import TSPSDK from 'tsp-sdk';

export const getLang = () => {
  const ua = navigator.userAgent;
  const langs = [
    'en',
    'sg',
    'vi',
    'id',
    'ms',
    'my',
    'th',
    'zhHans',
    'zhHant',
    'zh-Hans',
    'zh-Hant',
    'fa',
    'ms_my',
    'ms-my',
    'pt-BR',
    'en-PH',
    'en-ph',
  ];
  const arr = ua.split(' ');
  let locale = 'en';
  let tmp = '';
  arr.forEach((it) => {
    if (it.startsWith('locale/')) {
      // IsFuckingAndroid
      tmp = it.slice(it.indexOf('/') + 1);
    } else if (it.startsWith('locale=')) {
      // IsFuckingIOS
      tmp = it.slice(it.indexOf('=') + 1);
    }

    locale = langs.includes(tmp) ? tmp : 'en';
  });
  // 手动设置语言改这里
  // return 'th'
  return locale;
};

export function getLangCode(): string {
  // 就是从 useragent 获取当前语言的值
  let locale = getLang(); // 注意：这个值必须跟src/locales下文件名相同！！
  const myLangCode = ['ms', 'my', 'ms-my', 'ms_my'];
  const zhLangCode = ['zhHans', 'zh-Hans'];
  const brLangCode = ['pt-BR'];
  const vnLangCode = ['vi'];
  switch (true) {
    case myLangCode.includes(locale):
      locale = 'my';
      break;
    case zhLangCode.includes(locale):
      locale = 'zh';
      break;
    case brLangCode.includes(locale):
      locale = 'br';
      break;
    case vnLangCode.includes(locale):
      locale = 'vn';
      break;
    default:
      break;
  }
  return locale;
}

export const locale = getLangCode();

// useIntl 兼容 i18n 写法
export const t = function (id: string | { id: string }, slot?: { [key: string]: any }) {
  return getIntl(getLocale()).formatMessage(
    { id: typeof id === 'string' ? id : (id as any).id },
    slot,
  );
};

const fesResourcesMap: Record<string, number[]> = {
  id: [1517], // https://transify.sea.com/resources/1517
  my: [2384], // https://transify.sea.com/resources/2384
  br: [2838], // https://transify.sea.com/resources/2838
  ph: [3635], // https://transify.sea.com/resources/3635
  th: [3874], // https://transify.sea.com/resources/3874
  vn: [5673], // https://transify.sea.com/resources/5673
};

export const initLocale = () => {
  // 需注意：fast的语言标识和tsp不一致。
  const localeFromUserAgent: any = getLang();
  let json: any = {};
  let tspParam = {};
  let localeToSet = '';

  // eslint-disable-next-line default-case
  switch (BIZ_COUNTRY) {
    case 'br':
      json =
        localeFromUserAgent === 'pt-BR'
          ? require(`@/locales/br/br.json`)
          : require(`@/locales/br/en.json`);
      tspParam = {
        locale: localeFromUserAgent === 'pt-BR' ? 'pt-BR' : 'en',
      };
      break;
    case 'id':
      json =
        localeFromUserAgent === 'id'
          ? require(`@/locales/id/id.json`)
          : require(`@/locales/id/en.json`);
      tspParam = {
        locale: localeFromUserAgent === 'id' ? 'id' : 'en',
      };
      break;
    case 'ph':
      // PH只支持英文
      json = require(`@/locales/ph/en.json`);
      tspParam = {
        locale: 'en',
      };
      break;
    case 'my':
      if (localeFromUserAgent === 'zh-Hans') {
        json = require(`@/locales/my/zh-Hans.json`);
        localeToSet = 'zh-Hans';
      } else if (localeFromUserAgent === 'ms-my' || localeFromUserAgent === 'ms') {
        json = require(`@/locales/my/ms-my.json`);
        localeToSet = 'ms-my';
      } else {
        json = require(`@/locales/my/en.json`);
        localeToSet = 'en';
      }
      tspParam = {
        locale: localeToSet,
      };
      break;
    case 'th':
      json =
        localeFromUserAgent === 'th'
          ? require(`@/locales/th/th.json`)
          : require(`@/locales/th/en.json`);
      tspParam = {
        locale: localeFromUserAgent === 'th' ? 'th' : 'en',
      };
      break;
    case 'vn':
      json =
        localeFromUserAgent === 'vi'
          ? require(`@/locales/vn/vi.json`)
          : require(`@/locales/vn/en.json`);
      tspParam = {
        locale: localeFromUserAgent === 'vi' ? 'vi' : 'en',
      };
      break;
  }

  // 用代码里的文件作为一个兜底处理，设成英语是因为umi的默认语言是英语
  addLocale(locale, json);

  // 调SDK的方法去请求文案
  const translator = new TSPSDK({
    name: 'fastescrow',
    isSSR: false,
  });
  translator
    .create({
      env: REACT_APP_ENV === 'live' ? 'live' : 'nolive', // determine to use live or nonlive api， default live
      locale: localeFromUserAgent, // language code, en, id, th, zh-Hans etc, case insensitive
      region: BIZ_COUNTRY === 'br' ? 'br' : 'sg', // optional, default 'sg', set 'br' for br site; used for TSP API domain suffix
      translation: {
        resources: fesResourcesMap?.[BIZ_COUNTRY], // fastescrow 的 resource id
      },
      ...tspParam,
    })
    .then((res) => {
      const transifyJson = res?.translation || {};
      addLocale(locale, transifyJson);
    });
};
