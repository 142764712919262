import { BaseUtils } from './base';
import {
  callHandler,
  navigate as originNavigate,
  navigateAppRL as originNavigateAppRL,
  navigateAppPath as originNavigateAppPath,
  configurePage,
  requestAppPermission,
  checkAppPermission,
  WebBridgeCommand,
  registerHandler,
  unregisterHandler,
  registerViewWillReappear,
  WebBridgeEventListener,
} from '@shopee/web-bridge-sdk';
import { isString, isNumber, isObject } from 'lodash';
import { Toast } from '@credit/tips';
import { MDAP } from '@/utils/mdap';

declare type CommandCallbackFunction<T> = (data: T | {}) => void;

interface ITrackPerfEvent {
  subtype?: number;
  payload?: any;
  sampleRate?: number;
}

interface IError extends Error {
  response?: object;
}

const PERF_EVENT_TYPE = {
  getAuthCodeFail: 13,
  getInitInfoFail: 14,
  analysisDFLose: 17,
};

type IPerfEventType = keyof typeof PERF_EVENT_TYPE;

interface SaveImageOption {
  url: string;
  filename: string;
  popupText: string;
  successText: string;
  onSuccess?: () => void;
}

export interface IPaymentParam {
  data: string | Record<string, any>;
  selectedParentIndex?: number;
  selectedChildIndex?: number;
  key: string;
  metaKey?: string;
  trackData?: any;
}

export enum GetLocationRespStatus {
  Failure = 0,
  Success = 1,
  NoPermission = 2,
}

export enum GETLocationRespErrorCode {
  Success = 0,
  LocationServiceOff = 2, // 定位服务关闭
  NotGranted = 4101, // 未授予权限
  Timeout = -1, // 这个是我们自己定义的超时错误码，bridge 文档没有这个值
}
export interface ILocation {
  status: GetLocationRespStatus;
  longitude: string;
  latitude: string;
  error?: GETLocationRespErrorCode; // currently used in Android
}

/**
 * @version 2.0 Event Collection from Bridge
 *
 */
export enum IBridgeEvents {
  GetLocation = 'getLocation',
  RequestAppPermission = 'requestAppPermission',
  GetNetworkInfo = 'getNetworkInfo',
  RequestAuthCodeFromCoreAuth = 'requestAuthCodeFromCoreAuth',
  GetRiskToken = 'getRiskToken',
  GetDeviceFingerprint = 'getDeviceFingerprint',
  InitSoftToken = 'initSoftToken',
  GetSoftToken = 'getSoftToken',
  PlantSeed = 'plantSeed',
  GetDeviceInfo = 'getDeviceInfo',
  GetData = 'getData',
  DownloadFile = 'downloadFile',
  SaveImage = 'saveImage',
  ShowPopUp = 'showPopUp',
  DsInitDownloader = 'dsInitDownloader',
  DsGetAppnameRegion = 'dsGetAppnameRegion',
  DsLoadModel = 'dsLoadModel',
  DsDownloadProductLineModels = 'dsDownloadProductLineModels',
  TrackPerformanceEvent = 'trackPerformanceEvent',
  GetTongdunBlackbox = 'getTongdunBlackbox',
  PreviewFile = 'previewFile',
  PickSystemContact = 'pickSystemContact',
  RequestLoginNonce = 'requestLoginNonce',
  UpdateLanguage = 'updateLanguage',
  TrackEvent = 'trackEvent',
  DimNavbar = 'dimNavbar',
}

// 基于回调封装成promise的bridge
export const ActionPromise = [
  WebBridgeCommand.ConfigurePage,
  WebBridgeCommand.PopWebView,
  WebBridgeCommand.Save,
  WebBridgeCommand.GetAppInfo,
  WebBridgeCommand.Navigate,
  WebBridgeCommand.OpenExternalLink,
  WebBridgeCommand.NavigateAppRL,
  WebBridgeCommand.NavigateAppPath,
  WebBridgeCommand.RequestAppPermission,

  IBridgeEvents.ShowPopUp,
  IBridgeEvents.DsInitDownloader,
  IBridgeEvents.DsGetAppnameRegion,
  IBridgeEvents.DsLoadModel,
  IBridgeEvents.DsDownloadProductLineModels,
];

// 有些bridge没有提供回调的，直接返回的结果
export const ActionStatic = [WebBridgeCommand.TrackBIEvent, 'trackPerformanceEvent'];

const navigate = (
  ...params: Parameters<typeof originNavigate>
): ReturnType<typeof originNavigate> => {
  MDAP.bridgeCallReport(...params);
  return originNavigate(...params);
};
const navigateAppRL = (
  ...params: Parameters<typeof originNavigateAppRL>
): ReturnType<typeof originNavigateAppRL> => {
  MDAP.bridgeCallReport(...params);
  return originNavigateAppRL(...params);
};
const navigateAppPath = (
  ...params: Parameters<typeof originNavigateAppPath>
): ReturnType<typeof originNavigateAppPath> => {
  MDAP.bridgeCallReport(...params);
  return originNavigateAppPath(...params);
};

export class Bridge extends BaseUtils {
  readonly callHandler = callHandler;
  readonly navigate = navigate;
  readonly navigateAppRL = navigateAppRL;
  readonly navigateAppPath = navigateAppPath;
  readonly configurePage = configurePage;
  readonly requestAppPermission = requestAppPermission;
  readonly checkAppPermission = checkAppPermission;
  readonly registerHandler = registerHandler;
  readonly unregisterHandler = unregisterHandler;

  readonly callWebview = (url: string, title?: any, popSelf?: 1 | 0): void => {
    navigate({
      url,
      navbar: {
        isTransparent: 0,
        navbarStyle: 0,
        title: isString(title) ? title : '',
      },
      popSelf,
    });
  };

  readonly call = async (
    action: IBridgeEvents | WebBridgeCommand,
    params: Record<string, any> = {},
  ) => {
    const delay = 15000;

    return Promise.race([
      new Promise((resolve) => {
        callHandler(action as any, params, resolve);
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          const error: IError = new Error();
          error.response = {
            error: 0,
            errorMessage: 'time out',
          };
          reject(error);
        }, delay);
      }),
    ])
      .then((res) => res)
      .catch((e) => {
        // sEE.emit(BRIDGE_CALL_TIMEOUT, action, delay)
        return e?.response || {};
      });
  };

  readonly trackPerformanceEvent = async (
    type: IPerfEventType | ITrackPerfEvent,
    payload?: any,
    sampleRate?: number,
  ) => {
    let eventData: any = null;
    if (isObject(type)) {
      eventData = type;
    } else if (isString(type) && isNumber(PERF_EVENT_TYPE[type])) {
      eventData = {
        subtype: PERF_EVENT_TYPE[type],
        payload,
        sampleRate,
      };
    } else {
      return;
    }
    // @ts-ignore
    const res = await this.call('trackPerformanceEvent', eventData);
    return res;
  };

  // 保存图片到本地相册
  readonly saveImage = (opt: SaveImageOption) => {
    const { url, filename, successText, popupText, onSuccess } = opt;
    requestAppPermission(
      {
        permissionList: ['gallery'],
        popupText,
      },
      (res: any) => {
        console.log('requestAppPermission', res);
        // bridge status统一用1表示成功
        if (res.status === 1) {
          // https://confluence.shopee.io/pages/viewpage.action?pageId=31438459
          // 安卓的saveImage带不了cookie，只能用downloadFile API
          if (this.isAndroid()) {
            // iOS会自动校验是否开启了权限，安卓需要根据checkAppPermission判断用户是否开启了指定相册权限
            checkAppPermission(
              {
                permissionList: ['gallery'],
              },
              (response: any) => {
                console.log('checkAppPermission', response);
                console.log(
                  'checkAppPermission authorizationStatuses',
                  response?.data?.authorizationStatuses,
                );
                // authorizationStatuses Array of "UInteger":
                //  - 0: Not determined
                //  - 1: Granted
                //  - 2: Denied
                //  - 3: Not supported
                if (response?.data?.authorizationStatuses?.[0] === 1) {
                  Toast.loading();
                  callHandler(
                    // @ts-ignore
                    'downloadFile',
                    {
                      url,
                      fileName: filename,
                    },
                    (resp: any) => {
                      if (resp.error === 0) {
                        console.log('downloadFile', resp);
                        Toast.success(successText);
                        onSuccess?.();
                      } else {
                        Toast.fail('Error');
                      }
                    },
                  );
                } else {
                  Toast.fail(popupText);
                }
              },
            );
          } else {
            // IOS 使用bridge saveImage 正常 注意saveImage自带Toast
            callHandler(
              // @ts-ignore
              'saveImage',
              {
                imageUrl: url,
                filename,
              },
              () => {
                callHandler?.();
              },
            );
          }
        }
      },
    );
  };

  // 保存pdf格式文件
  readonly savePdf = (opt: SaveImageOption) => {
    const { url, filename, successText, onSuccess } = opt;
    // https://confluence.shopee.io/pages/viewpage.action?pageId=31438459
    // 安卓的saveImage带不了cookie，只能用downloadFile API
    if (this.isAndroid()) {
      Toast.loading();
      callHandler(
        // @ts-ignore
        'downloadFile',
        {
          url,
          fileName: filename,
        },
        (resp: any) => {
          console.log(resp, 'downloadFile resp.........');
          if (resp.error === 0) {
            Toast.success(successText);
            onSuccess?.();
          } else {
            Toast.fail('Error');
          }
        },
      );
    } else {
      // IOS 使用bridge previewFile 先预览pdf，然后用户点击保存到本地 注意saveImage自带Toast
      callHandler(
        // @ts-ignore
        'previewFile',
        {
          url,
          fileName: filename,
        },
        (res) => {
          console.log('previewFile', res);
        },
      );
    }
  };

  readonly previewPdf = (opt: Pick<SaveImageOption, 'url' | 'filename'>) => {
    const { url, filename } = opt;
    callHandler(
      // @ts-ignore
      'previewFile',
      {
        url,
        fileName: filename,
      },
      (res) => {
        console.log('previewFile', res);
      },
    );
  };

  readonly closeWebview = () => {
    // @ts-ignore
    callHandler('popWebView', {});
  };

  readonly goEntry = () => {
    this.closeWebview();
  };

  /**
   * 绑定事件
   * @param event string
   * @param fn 事件回调
   * @returns unbind func
   */
  readonly bind = (event: string, fn: (...args: any[]) => any) => {
    const unRegisterId = registerHandler(event as WebBridgeEventListener, fn);
    return () => unregisterHandler(event as WebBridgeEventListener, unRegisterId);
  };

  /**
   * 解绑bridge事件
   * @param event 事件名
   * @param fn 需要解绑的函数
   */
  readonly unBind = (event: string, unBindId?: any) => {
    unregisterHandler(event as WebBridgeEventListener, unBindId);
  };

  /**
   * 调用rn
   * @param apprl rn的地址
   * @param params 传递给rn的详细参数
   */
  readonly callNavigateAppRL = (apprl: string, params: any = {}) => {
    navigateAppRL({
      apprl,
      params,
    });
  };

  readonly viewWillReappear = (
    params: {
      needFormat?: boolean;
    } = {},
  ) => {
    const { needFormat = true } = params ?? {};
    return new Promise<any>((resolve, reject) => {
      const registerResp = registerViewWillReappear((e: any) => {
        const result = e;
        registerResp.unregister();

        if (!needFormat) {
          resolve(result);
          return;
        }
        console.info('RN return', e);
        try {
          if (e && e.data) {
            const channelData = JSON.parse(e.data);
            if (channelData && channelData.data && channelData.data.includes('channel_id')) {
              const selection = channelData.data;
              resolve(selection);
            }
            resolve('');
          } else {
            resolve('');
          }
        } catch (err) {
          reject(err);
        }
      });
    });
  };

  /**
   * 获取还款选项
   * @param param0 IPaymentParam
   * @returns string
   */
  readonly getPaymentMethod = async ({
    data,
    selectedParentIndex = -1,
    selectedChildIndex = -1,
    key,
    metaKey,
    trackData,
  }: IPaymentParam): Promise<string> => {
    if (!data) {
      throw new Error('Unknown Data Key');
    }

    await this.call(WebBridgeCommand.Save, { key, data });

    if (trackData && metaKey) {
      await this.call(WebBridgeCommand.Save, {
        key: metaKey,
        data: JSON.stringify(trackData),
      });
    }

    const reactNativeConfig: { [key: string]: any } = {
      // bundle: 'shopee',
      // module: 'PAYMENT_SELECTION',
      key,
      selectedParentIndex,
      selectedChildIndex,
    };

    metaKey && (reactNativeConfig.metaKey = metaKey);

    this.callNavigateAppRL('rn/@shopee-rn/shopeepay/PAYMENT_SELECTION', reactNativeConfig);

    const resp = await this.viewWillReappear({
      needFormat: false,
    });

    try {
      const channelData = JSON.parse(resp?.data);
      console.log(channelData, 'channelData....');
      return channelData?.data ?? '';
    } catch (error) {
      return '';
    }
  };

  /**
   * 异步call bridge
   * @param action
   * @param params
   * @returns
   */
  promiseCall<T extends Record<string, any>, U extends Record<string, any>>(
    action: IBridgeEvents | WebBridgeCommand,
    params: T = {} as T,
  ): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      try {
        callHandler(action as WebBridgeCommand, params, resolve as CommandCallbackFunction<U>);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 带有timeout的异步call bridge
   * @param action IBridgeEvents
   * @param params Record<string, any>
   * @param time number
   * @returns
   */
  async promiseCallWithTimeout<T extends Record<string, any>, U extends Record<string, any>>(
    action: IBridgeEvents | WebBridgeCommand,
    params: T = {} as T,
    time: number,
  ) {
    try {
      return await Promise.race([
        this.promiseCall<T, U>(action, params),
        new Promise((_, reject) => {
          setTimeout(() => {
            const error: any = new Error();
            error.response = {
              error: 0,
              errorMessage: 'time out',
              extra: {
                action,
                url: params?.url || undefined,
              },
            };
            reject(error);
          }, time);
        }),
      ]);
    } catch (e: any) {
      const errorType = e?.response?.errorMessage === 'time out' ? 'timeout' : 'nativate';
      return e?.response || {};
    }
  }

  /**
   * 校验权限
   * @param permissionList 需要检查的权限列表
   * @returns Promise<boolean>
   */
  async checkPermission(permissionList: string[] = []): Promise<boolean[]> {
    const { resultList } = await this.call(WebBridgeCommand.CheckAppPermission, {
      permissionList,
    });

    if (Array.isArray(resultList)) {
      return resultList;
    }

    if (typeof resultList !== 'string') {
      return [];
    }

    try {
      return JSON.parse(resultList);
    } catch (error) {
      return [];
    }
  }

  /**
   * @returns Promise
   */
  getAppInfo() {
    return this.call(WebBridgeCommand.GetAppInfo);
  }

  /**
   * @returns Promise
   */
  async getNetworkInfo(): Promise<any> {
    return this.call(IBridgeEvents.GetNetworkInfo);
  }

  /**
   * 获取设备信息
   * @returns {}
   */
  async getDeviceInfo() {
    const version = this.getAppVersion();
    if (version <= 24500) {
      return {};
    }

    try {
      const res = await this.promiseCallWithTimeout(IBridgeEvents.GetDeviceInfo, {}, 5000);
      if (res?.error === 0) {
        // 0 - success
        return res?.data?.deviceinfo_LF;
      }
    } catch (e) {
      return {};
    }
  }

  /**
   * 获取GPS信息
   * @param params 参数
   * @returns void
   */
  async getLocation(params: Record<string, any> = {}): Promise<ILocation> {
    try {
      const resp = await this.promiseCallWithTimeout(IBridgeEvents.GetLocation, params, 9000);
      const { longitude, latitude, status } = resp;

      return {
        longitude: typeof longitude === 'number' && !isNaN(longitude) ? `${longitude}` : '',
        latitude: typeof latitude === 'number' && !isNaN(latitude) ? `${latitude}` : '',
        status,
      };
    } catch (e) {
      return {
        latitude: '',
        longitude: '',
        status: GetLocationRespStatus.Failure,
      };
    }
  }
}

export default new Bridge();
