import crypto from 'crypto';
import NodeRSA from 'node-rsa';
import { extend, ResponseError, RequestMethod } from 'umi-request';
import { v4 } from 'uuid';
import config from '@/config';
import { useFormatMsg, locale } from '@/utils/locale';
import common from '@/utils/v2/common';
import { ICustomMessage } from '@/utils/mdap';
import { MDAPApiErrorReport } from '@/utils/businessErrorReport';
import request from '@/utils/v2/request';

const formatMessage = useFormatMsg();

export interface IPureRequestParams {
  method: 'POST' | 'PUT' | 'DELETE' | 'GET';
  url: string;
  requestHeader?: Record<string, any>;
  options?: Record<string, any>;
  requestData?: Record<string, unknown>;
}

export class BaseService {
  private umiRequest: RequestMethod;

  private baseHeaders = {
    'X-Kredit-Req-Source': '1',
    'X-Kredit-Req-Language': locale,
    Authorization: config.Authorization,
  };

  private secretKey = config.secretKey; // rsa私钥
  private publicKey = config.publicKey; // rsa公钥

  private version = 1; // 版本， 默认为1
  private platForm = 1; // 平台，1: shopee
  private region: string = BIZ_COUNTRY; // 国家或地区
  private appVersion: number; // app版本号
  private rnVersion: number; // rn版本号

  constructor() {
    this.appVersion = common.getAppVersion();
    this.rnVersion = common.getRnVersion();

    this.umiRequest = extend({
      prefix: '/api',
      errorHandler: this.defaultErrorHandler, // 默认错误处理
      credentials: 'include', // 默认请求是否带上cookie
      useCache: false,
      timeout: 10000, // 请求失效的时间
      validateCache: () => false,
      requestType: 'json',
      responseType: 'json',
      getResponse: true,
      parseResponse: true,
      throwErrIfParseFail: true,
    });
  }

  /**
   * 对请求参数进行签名
   * @param data
   * @param encode
   * @returns string
   */
  protected signature(data: Record<string, any>, encode = 'base64') {
    try {
      const str = JSON.stringify(data) + this.secretKey;
      const hash = crypto.createHash('sha256');
      hash.update(str);
      const hashString = hash.digest('hex');
      const key = new NodeRSA(this.publicKey, 'pkcs8-public', {
        encryptionScheme: 'pkcs1_oaep',
        signingScheme: 'pkcs1-sha1',
      });

      const signature = key.encrypt(hashString, encode as NodeRSA.Encoding);
      return signature;
    } catch (error) {
      throw new Error('Sigature Error');
    }
  }

  /**
   * 设置头部信息
   * @param signature
   * @param headers
   * @returns
   */
  protected setHeaders(headers: Record<string, any>) {
    return {
      ...this.baseHeaders,
      'X-Kredit-Trace-ID': v4(),
      'X-Kredit-Start-Timestamp': `${Date.now()}`,
      ...headers,
    };
  }

  /**
   * 请求错误的处理
   * @param e ResponseError
   * @returns void
   */
  private defaultErrorHandler(e: ResponseError) {
    const { type, response = {} as Response } = e || {};
    const headers = (e?.request?.options?.headers as Record<string, string>) ?? {};
    const traceId = headers?.['X-Kredit-Trace-ID'] ?? '';
    const url = e?.request?.url ?? '';

    MDAPApiErrorReport(
      {
        traceId,
        apiPath: url,
        options: e?.request || {},
        response: e?.response || {},
        user: common.getAppState('userInfo.userInfo') ?? {},
      },
      ICustomMessage.API_UNCAUGHT_ERROR,
    );

    return {
      // 避免response的status等于0的情况
      code: response?.status === 0 ? -1 : response?.status,
      traceId,
      msg: formatMessage({ id: 'network_error' }),
      errDeal: true,
      result: {},
    };
  }

  /**
   *
   *
   * @description 纯粹的 request, 不包含调用Bridge, 设置toast等
   * @return {*}
   * @memberof BaseService
   */
  async pureRequest({
    method = 'POST',
    url,
    requestData = {},
    requestHeader = {},
    options = {},
  }: IPureRequestParams) {
    try {
      // 重组Reqeust Header
      const headers = this.setHeaders(requestHeader);

      const resp = await this.umiRequest(url, {
        method,
        headers,
        data: requestData,
        ...options,
      });

      return resp;
    } catch (error) {
      console.error('umiRequest error', error);
      return {
        code: -1,
        msg: 'request error',
        result: {},
      };
    }
  }

  async post(url: string, params: Record<string, any> = {}) {
    return request.post(url, {
      ...params,
    });
  }
}
