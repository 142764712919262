import { Expose } from 'class-transformer';

export class UpcomingResponseModel {
  /**
   * 订单总可放款金额
   */
  @Expose()
  total_mortgage_amount!: number;

  /**
   * 用户最大放款额度
   */
  @Expose()
  total_limit!: number;

  /**
   * 用户可用放款额度
   */
  @Expose()
  available_limit!: number;

  /**
   * 用户配置表中的放款比例
   */
  @Expose()
  user_percentage!: number;

  /**
   * @deprecated in refactor
   * 风控判定的放款比例
   */
  @Expose()
  risk_percentage!: number;

  /**
   * 风控判定的放款比例
   */
  @Expose()
  system_percentage!: number;

  /**
   * 预计放款比例，在未调整情况下未以上两者中较小的一个
   */
  @Expose()
  schedule_disburse_percentage!: number;

  /**
   * @deprecated in refactor
   * 预计放款比例是否由系统调整过
   */
  @Expose()
  is_adjusted!: boolean;

  @Expose()
  is_adjust!: boolean;

  /**
   * @deprecated in refactor
   * 预计放款金额
   */
  @Expose()
  schedule_disburse_amount!: number;

  /**
   * 预计放款金额，扣掉了服务费
   */
  @Expose()
  actual_disburse_amount!: number;

  /**
   * 用户ID
   */
  @Expose()
  user_id!: number;

  /**
   * 用户类型
   */
  @Expose()
  merchant_id!: number;

  /**
   * 利息率
   */
  @Expose()
  interest_rate!: number;

  /**
   * 预计放款时间
   */
  @Expose()
  schedule_disburse_time!: number;

  /**
   * 订单总可放款金额
   */
  @Expose()
  list?: UpcomingListModel[];

  /**
   * @deprecated in refactor
   * 即将提前放款的预估 anticipate amount, 没有扣掉服务费的
   */
  @Expose()
  anticipate_amount!: number;

  /**
   * @deprecated in refactor
   * 即将提前放款的预估服务费
   */
  @Expose()
  service_charge!: number;

  /**
   * 服务费
   */
  @Expose()
  service_charge_amount!: number;

  /**
   * 服务费率
   */
  @Expose()
  service_charge_rate!: number;

  /**
   * ID特有。用户实际服务费率（加上阶梯费率后）
   */
  @Expose()
  current_service_fee!: number;

  /**
   * 放款金额，没有扣掉服务费的
   */
  @Expose()
  principal_amount!: number;

  /**
   * 是否在免息期内
   */
  @Expose()
  is_in_free_period!: boolean;

  @Expose()
  discounted_service_charge_amount: number = 0;
}

export class UpcomingListModel {
  /**
   * 订单Id
   */
  @Expose()
  entity_id!: number;

  /**
   * Refactor
   * Id
   * 用于App端增量获取数据，fast订单ID，请使用这个ID置入cursor
   */
  @Expose()
  id!: number;

  /**
   * @deprecated in refactor
   * 用于App端增量获取数据，订单ID，请使用这个ID置入cursor
   */
  @Expose()
  fast_escrow_id!: number;

  /**
   * 订单序列码
   */
  @Expose()
  order_sn!: number;

  /**
   * 订单图片
   */
  @Expose()
  order_image!: number;

  /**
   * 订单买家名
   */
  @Expose()
  buyer_name!: number;

  /**
   * 订单链接
   */
  @Expose()
  order_url!: number;

  /**
   * 订单可放款金额
   */
  @Expose()
  mortgage_amount!: number;

  /**
   * 订单预估放款金额
   */
  @Expose()
  schedule_disburse_amount!: number;

  /**
   * 订单额度是否被调整
   */
  @Expose()
  is_adjusted!: boolean;
}
