import { EnKeys } from '@/locales/en';
import { getFormatObjKeyMsg, getObservableMsg } from '@credit/utils';
import { createIntl, createIntlCache } from 'umi';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export const getLang = () => {
  const ua = navigator.userAgent;
  const langs = [
    'en',
    'sg',
    'vi',
    'id',
    'ms',
    'my',
    'th',
    'zhHans',
    'zhHant',
    'zh-Hans',
    'zh-Hant',
    'fa',
    'ms_my',
    'ms-my',
    'pt-BR',
    'en-PH',
    'en-ph',
  ];
  const arr = ua.split(' ');
  let locale = 'en';
  let tmp = '';
  arr.forEach((it) => {
    if (it.startsWith('locale/')) {
      // IsFuckingAndroid
      tmp = it.slice(it.indexOf('/') + 1);
    } else if (it.startsWith('locale=')) {
      // IsFuckingIOS
      tmp = it.slice(it.indexOf('=') + 1);
    }

    locale = langs.includes(tmp) ? tmp : 'en';
  });
  // 手动设置语言改这里
  // return 'th'
  return locale;
};

export function getLangCode(): string {
  // 就是从 useragent 获取当前语言的值
  let locale = getLang(); // 注意：这个值必须跟src/locales下文件名相同！！
  const myLangCode = ['ms', 'my', 'ms-my', 'ms_my'];
  const zhLangCode = ['zhHans', 'zh-Hans'];
  const brLangCode = ['pt-BR'];
  const vnLangCode = ['vi'];
  switch (true) {
    case myLangCode.includes(locale):
      locale = 'my';
      break;
    case zhLangCode.includes(locale):
      locale = 'zh';
      break;
    case brLangCode.includes(locale):
      locale = 'br';
      break;
    case vnLangCode.includes(locale):
      locale = 'vn';
      break;
    default:
      break;
  }
  return locale;
}

export const locale = getLangCode();

// 用于在一些 constant 文件里直接使用。（否则若从umi里直接import formatMessage 会得到undefined）
const cache = createIntlCache();
const messages = require(`@/locales/${locale}`).default;
const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

/**
 * @deprecated 使用 useFormatMsg 代替
 */
export const formatMessage = (
  info: { id: EnKeys },
  value?: Record<string, string | number | boolean | null | undefined | Date | any>,
) => intl.formatMessage(info, value);

export const formatObservableMsg = getObservableMsg<EnKeys>(formatMessage);
export const formatObjKeyMsg = getFormatObjKeyMsg<EnKeys>(formatMessage);

/**
 * @returns 返回 formatMessage 函数，在 hooks 中使用泛型来约束 id 的类型
 */
export function useFormatMsg<T extends EnKeys>() {
  return (
    info: { id: T },
    value?: Record<string, string | number | boolean | null | undefined | Date | any>,
  ) => intl.formatMessage(info, value);
}

const typeTemplate = '${label} is not a valid ${type}';

function mergeLocale<T, P>(base: T, patch: P) {
  return merge(cloneDeep(base), patch);
}

const antMobileLocaleBase = {
  locale: 'en',
  common: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    loading: 'Loading...',
  },
  Calendar: {
    markItems: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    renderYearAndMonth: (year: number, month: number) => `${year}/${month}`,
  },
  Cascader: {
    placeholder: 'Selecting',
  },
  Dialog: {
    ok: 'OK',
  },
  ErrorBlock: {
    default: {
      title: 'Oops, something went wrong',
      description: 'Please wait a minute and try again',
    },
    busy: {
      title: 'Oops, not loading',
      description: 'Try to refresh the page',
    },
    disconnected: {
      title: 'Network is busy',
      description: 'Try to refresh the page',
    },
    empty: {
      title: "Hmm, couldn't find that...",
      description: 'Want to try a new search?',
    },
  },
  Form: {
    required: 'Required',
    optional: 'Optional',
    defaultValidateMessages: {
      default: 'Field validation error for ${label}',
      required: 'Please enter ${label}',
      enum: '${label} must be one of [${enum}]',
      whitespace: '${label} cannot be a blank character',
      date: {
        format: '${label} date format is invalid',
        parse: '${label} cannot be converted to a date',
        invalid: '${label} is an invalid date',
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: '${label} must be ${len} characters',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be up to ${max} characters',
        range: '${label} must be between ${min}-${max} characters',
      },
      number: {
        len: '${label} must be equal to ${len}',
        min: '${label} must be minimum ${min}',
        max: '${label} must be maximum ${max}',
        range: '${label} must be between ${min}-${max}',
      },
      array: {
        len: 'Must be ${len} ${label}',
        min: 'At least ${min} ${label}',
        max: 'At most ${max} ${label}',
        range: 'The amount of ${label} must be between ${min}-${max}',
      },
      pattern: {
        mismatch: '${label} does not match the pattern ${pattern}',
      },
    },
  },
  ImageUploader: {
    uploading: 'Uploading...',
  },
  Mask: {
    name: 'Mask',
  },
  Modal: {
    ok: 'OK',
  },
  PullToRefresh: {
    pulling: 'Scroll down to refresh',
    canRelease: 'Release to refresh immediately',
    complete: 'Refresh successful',
  },
};

export const antMobileLocale = mergeLocale(antMobileLocaleBase, {
  locale,
  common: {
    confirm: intl.formatMessage({ id: 'antd.ok' }),
    cancel: intl.formatMessage({ id: 'antd.dismiss' }),
    loading: 'Loading...',
  },
  Calendar: {
    markItems: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    renderYearAndMonth: (year: number, month: number) => `${year}/${month}`,
  },
  Cascader: {
    placeholder: 'Selecting',
  },
  Dialog: {
    ok: intl.formatMessage({ id: 'ok' }),
  },
  ErrorBlock: {
    default: {
      title: 'Oops, something went wrong',
      description: 'Please wait a minute and try again',
    },
    busy: {
      title: 'Oops, not loading',
      description: 'Try to refresh the page',
    },
    disconnected: {
      title: 'Network is busy',
      description: 'Try to refresh the page',
    },
    empty: {
      title: "Hmm, couldn't find that...",
      description: 'Want to try a new search?',
    },
  },
  Form: {
    required: 'Required',
    optional: 'Optional',
    defaultValidateMessages: {
      default: 'Field validation error for ${label}',
      required: 'Please enter ${label}',
      enum: '${label} must be one of [${enum}]',
      whitespace: '${label} cannot be a blank character',
      date: {
        format: '${label} date format is invalid',
        parse: '${label} cannot be converted to a date',
        invalid: '${label} is an invalid date',
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: '${label} must be ${len} characters',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be up to ${max} characters',
        range: '${label} must be between ${min}-${max} characters',
      },
      number: {
        len: '${label} must be equal to ${len}',
        min: '${label} must be minimum ${min}',
        max: '${label} must be maximum ${max}',
        range: '${label} must be between ${min}-${max}',
      },
      array: {
        len: 'Must be ${len} ${label}',
        min: 'At least ${min} ${label}',
        max: 'At most ${max} ${label}',
        range: 'The amount of ${label} must be between ${min}-${max}',
      },
      pattern: {
        mismatch: '${label} does not match the pattern ${pattern}',
      },
    },
  },
  ImageUploader: {
    uploading: 'Uploading...',
  },
  Mask: {
    name: 'Mask',
  },
  Modal: {
    ok: 'OK',
  },
  PullToRefresh: {
    pulling: 'Scroll down to refresh',
    canRelease: 'Release to refresh immediately',
    complete: 'Refresh successful',
  },
});

export const antMobileV2Locale = {
  locale,
  Pagination: {
    prevText: 'Prev',
    nextText: 'Next',
  },
  DatePicker: {
    okText: intl.formatMessage({ id: 'antd.ok' }),
    dismissText: intl.formatMessage({ id: 'antd.dismiss' }),
    extra: intl.formatMessage({ id: 'antd.extra' }),
    DatePickerLocale: {
      year: '',
      month: '',
      day: '',
      hour: '',
      minute: '',
      am: 'AM',
      pm: 'PM',
    },
  },
  DatePickerView: {
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: '',
    am: 'AM',
    pm: 'PM',
  },
  InputItem: {
    confirmLabel: 'Done',
    backspaceLabel: 'Backspace',
    cancelKeyboardLabel: 'CancelKeyboard',
  },
  Picker: {
    okText: 'Ok',
    dismissText: 'Cancel',
    extra: 'please select',
  },
  SearchBar: {
    cancelText: 'Cancel',
  },
  Menu: {
    okText: 'Ok',
    cancelText: 'Cancel',
  },
  PullToRefresh: {
    activateText: 'Refresh immediately after release',
    deactivateText: 'Pull down to refresh',
    finishText: 'Finish refresh',
  },
  Calendar: {
    title: 'Calendar',
    today: 'Today',
    month: 'Month',
    year: 'Year',
    am: 'AM',
    pm: 'PM',
    dateTimeFormat: 'MM/dd/yyyy w hh:mm',
    dateFormat: 'yyyy/MM/dd w',
    noChoose: 'No Choose',
    week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fir', 'Sat'],
    clear: 'Clear',
    selectTime: 'Select Time',
    selectStartTime: 'Select Start Time',
    selectEndTime: 'Select End Time',
    start: 'Start',
    end: 'End',
    begin: 'Start',
    over: 'End',
    begin_over: 'S/E',
    confirm: 'Confirm',
    monthTitle: 'yyyy/MM',
    loadPrevMonth: 'Load Prev Month',
    yesterday: 'Yesterday',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
  },
};

export const monthTextArr = [
  intl.formatMessage({ id: 'month1' }),
  intl.formatMessage({ id: 'month2' }),
  intl.formatMessage({ id: 'month3' }),
  intl.formatMessage({ id: 'month4' }),
  intl.formatMessage({ id: 'month5' }),
  intl.formatMessage({ id: 'month6' }),
  intl.formatMessage({ id: 'month7' }),
  intl.formatMessage({ id: 'month8' }),
  intl.formatMessage({ id: 'month9' }),
  intl.formatMessage({ id: 'month10' }),
  intl.formatMessage({ id: 'month11' }),
  intl.formatMessage({ id: 'month12' }),
];
