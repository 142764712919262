import IDStore from './index.id';
import MYStore from './index.my';
import BRStore from './index.br';
import PHStore from './index.ph';
import THStore from './index.th';
import VNStore from './index.vn';

export type BRStoreType = typeof BRStore;
export type IDStoreType = typeof IDStore;
export type MYStoreType = typeof MYStore;
export type PHStoreType = typeof PHStore;
export type THStoreType = typeof THStore;
export type VNStoreType = typeof VNStore;

type StoreType = BRStoreType | IDStoreType | MYStoreType | PHStoreType | THStoreType | VNStoreType;

export const stores: StoreType = require(`./index.${BIZ_COUNTRY}`).default;

const useStores = <T extends StoreType>(): T => {
  return stores as T;
};

export default useStores;
