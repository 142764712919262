import { history } from 'umi';
import { makeObservable, action, observable, runInAction } from 'mobx';
import { BaseService } from '../base';
import UserInfo from './api.interface';
import { UserInfoModel } from './userInfo.model';
import { RISK_REJECT_CODE, BIZ_TYPE, LOCK_TYPE, CodeType } from './userInfo.interface';
import { MDAP } from '@/utils/mdap';
import insight from '@/utils/v2/insight';
import { getAuthCode, getLoginCode, goWsaUrl } from '@/utils/v2/authcode';

const UNACTIVE = [30001, 30002, 30003, 90113, 90011]; // 黑名单用户错误码

export class UserInfoService extends BaseService {
  @observable userInfo: UserInfoModel = new UserInfoModel();

  constructor() {
    super();
    makeObservable(this);
  }

  isUnactivated(userInfo: UserInfoModel) {
    const userInfoState = userInfo;
    const { userStatus } = userInfoState;

    return userStatus < 20;
  }

  isFrozen(userInfo: UserInfoModel) {
    const { userStatus } = userInfo;

    return userStatus >= 30;
  }

  isWhatTypeFrozen(userInfo: UserInfoModel, type: LOCK_TYPE) {
    const { lockInfoList } = userInfo;

    return (
      this.isFrozen(userInfo) &&
      lockInfoList?.some(
        (item) => item?.biz_type === BIZ_TYPE.FastEscrow && item?.lock_type === type,
      )
    );
  }

  // 贷中贷后只在存量用户提示updateInfo和路由控制用到UC拒绝码。
  isInActivationCoolTime(userInfo: UserInfoModel) {
    const userInfoState: UserInfoModel = userInfo;
    const { riskRejectCode, coolDownEndTime } = userInfoState;

    return !!(
      this.isUnactivated(userInfoState) &&
      [
        RISK_REJECT_CODE.FRAUD_COOL_DOWN1,
        RISK_REJECT_CODE.FRAUD_COOL_DOWN2,
        RISK_REJECT_CODE.UNDERWRITING,
        RISK_REJECT_CODE.VERIFICATION_COOL_DOWN,
      ].includes(riskRejectCode) &&
      coolDownEndTime
    );
  }

  @action
  async getUserInfo() {
    const authCodeResp = await getLoginCode();

    if (!authCodeResp.token) {
      // errcode=0，表示是RN没取到，此时要跳转wsa url兜底；否则表示wsa也没取到，展示错误页就行
      authCodeResp.errorCode === 0 ? goWsaUrl() : history.replace({ pathname: '/exception' });
      return false;
    }

    const requestHeaderParams =
      authCodeResp.type === CodeType.AuthCode
        ? { 'X-Kredit-Req-AuthCode': authCodeResp.token }
        : { 'X-Kredit-Req-Nonce': authCodeResp.token };

    const { code: loginCode, result: loginResult } = await this.post(UserInfo.API.login, {
      headers: requestHeaderParams,
    });
    if (loginCode === 0) {
      const { code, result } = await this.post(UserInfo.API.get_user_info);
      if (code === 0) {
        const userInfo = {
          userId: loginResult?.user_id ?? '',
          lockInfoList: result.lock_info_list || [],
          userStatus: result.user_status,
          isStockUser: result.is_stock_user,
          screenStatus: result.screen_status,
          kycStatus: result.kyc_status,
          fraudStatus: result.fraud_status,
          overdueStatus: result.overdue_status,
          riskRejectCode: result.risk_reject_code,
          coolDownEndTime: result.cool_down_end_time,
          totalLimit: result.total_limit,
          availableLimit: result.available_limit,
          isFirstIn: result.is_first_in,
        } as UserInfoModel;

        MDAP.addUserIdTag(userInfo.userId || '');

        insight.loginReport({
          status: 'success',
          type: authCodeResp.type === CodeType.AuthCode ? 'auth_code' : 'nonce_code',
        });

        // UC DR Frozen
        if (this.isWhatTypeFrozen(userInfo, LOCK_TYPE.DR_DISABLE_USER)) {
          history.push({ pathname: '/exception', state: { status: 'drfrozen' } });
          return false;
        }

        runInAction(() => {
          this.userInfo = {
            ...userInfo,
            isUnactivated: this.isUnactivated(userInfo),
            isFrozen: this.isFrozen(userInfo),
            isKYCFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.POST_KYC),
            isFraudFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.POST_FRAUD),
            isOverdueFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.OVERDUE),
            isSellerFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.SELLER_FREEZE),
            isCNPJFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.CNPJ_FREEZE),
            isICFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.IC_FREEZE),
            isFSKYCFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.FS_KYC_FROZEN),
            isUnderFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.UNDER_WRITING),
            isAccountClosureFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.CLOSURE),
            isCollectionFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.COLLECTION),
            isShopeeStatusFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.SHOPEE_STATUS_FROZEN),
            isSPPUnbindFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.AIRPAY_UNBINDING),
            isDPD60Frozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.DPD60),
            isPaymentRejectCoolDownFrozen: this.isWhatTypeFrozen(
              userInfo,
              LOCK_TYPE.PAYMENT_REJECTION_FREEZE,
            ),
            islogin: true,
          };
        });

        return true;
      }
      insight.loginReport({
        status: 'error',
        type: authCodeResp.type === CodeType.AuthCode ? 'auth_code' : 'nonce_code',
      });

      console.error('调用获取用户信息接口报错');
      return false;
    }

    if (UNACTIVE.includes(loginCode as number)) {
      console.error('用户未被邀请');
      history.push({ pathname: '/exception', state: { status: 'uninvite' } });
      return false;
    }

    console.error('调用登录接口报错');
    MDAP.loginFailReport();
    return false;
  }

  @action
  async updateUserInfo() {
    const { code, result } = await this.post(UserInfo.API.get_user_info);
    if (code !== 0) {
      return false;
    }

    const userInfo = {
      lockInfoList: result.lock_info_list || [],
      userStatus: result.user_status,
      isStockUser: result.is_stock_user,
      screenStatus: result.screen_status,
      kycStatus: result.kyc_status,
      fraudStatus: result.fraud_status,
      overdueStatus: result.overdue_status,
      riskRejectCode: result.risk_reject_code,
      coolDownEndTime: result.cool_down_end_time,
      totalLimit: result.total_limit,
      availableLimit: result.available_limit,
      isFirstIn: result.is_first_in,
    } as UserInfoModel;

    // UC DR Frozen
    if (this.isWhatTypeFrozen(userInfo, LOCK_TYPE.DR_DISABLE_USER)) {
      history.push({ pathname: '/exception', state: { status: 'drfrozen' } });
      // 这里返回true表示不需再请求后续接口了
      return true;
    }

    this.userInfo = {
      ...userInfo,
      isUnactivated: this.isUnactivated(userInfo),
      isFrozen: this.isFrozen(userInfo),
      isKYCFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.POST_KYC),
      isFraudFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.POST_FRAUD),
      isOverdueFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.OVERDUE),
      isSellerFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.SELLER_FREEZE),
      isCNPJFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.CNPJ_FREEZE),
      isICFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.IC_FREEZE),
      isFSKYCFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.FS_KYC_FROZEN),
      isUnderFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.UNDER_WRITING),
      isAccountClosureFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.CLOSURE),
      isCollectionFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.COLLECTION),
      isShopeeStatusFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.SHOPEE_STATUS_FROZEN),
      isSPPUnbindFrozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.AIRPAY_UNBINDING),
      isDPD60Frozen: this.isWhatTypeFrozen(userInfo, LOCK_TYPE.DPD60),
      isPaymentRejectCoolDownFrozen: this.isWhatTypeFrozen(
        userInfo,
        LOCK_TYPE.PAYMENT_REJECTION_FREEZE,
      ),
      islogin: true,
    };

    return true;
  }

  /**
   *
   *@description 上报insight
   */
  async requestInsight(requestData: Record<string, any>) {
    await this.pureRequest({
      url: UserInfo.API.insight_fe_chat,
      method: 'POST',
      requestData: requestData ?? {},
    });
  }
}
