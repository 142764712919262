import baseUtils from './base';
import bridge, { IBridgeEvents } from './bridge';
import insight from './insight';
import appInfo from '@/stores/appInfo';
import common from '@/utils/v2/common';
import {
  PerfEventType,
  CodeType,
  ExitState,
  HeaderType,
} from '@/stores/userInfo/userInfo.interface';

const genLoginUrl = (redirectUrl: string) => {
  let env = REACT_APP_ENV;
  env = ['uat', 'live'].indexOf(env) >= 0 ? env : 'test';
  // URL对象会自动处理编码
  const url = new URL(
    `https://${env !== 'live' ? `${env}.` : ''}${BRIDGE_HOST}/authenticate/oauth`,
  );
  // 设置使用场景为shopee app 的 webview
  url?.searchParams?.append('__mobile__', '1');
  // 设置使用场景为shopee app 的 version, 2 is ready for integration in Shopee App Webview when the user is not logged in
  url?.searchParams?.append('version', '2');
  // state 是自己定义的一个参数
  url?.searchParams?.append('state', 'fastescrow');
  // 设置client_id参数, credit 团队给的client_id为4
  url?.searchParams?.append('client_id', '4');
  // 设置redirectUrl参数
  url?.searchParams?.append('redirect_uri', redirectUrl);
  return url?.href;
};

export const goWsaUrl = () => {
  let url = window.location.href;
  if (url.indexOf('?') > -1) {
    url = url.substring(0, url.indexOf('?'));
  }
  const offlineQuery = window._offline_build_url_query;
  // 兼容离线包
  if (offlineQuery) {
    url = `${url}index.html?${offlineQuery}`;
  }
  // 回跳的url形如 'https://cashloan.test.shopeekredit.co.id/activate?code=xxx&&errcode=0&errmsg=OK';
  const loginUrl = genLoginUrl(url);
  console.log(loginUrl);
  console.log('login get nonceCode from wsa!');

  insight.loginReport({
    status: 'call',
    type: 'nonce_code',
  });

  window.location.href = loginUrl;
};

// 延迟
const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

const requestAuthCodeFromCoreAuth = async (params?: { clientID?: string }) => {
  if (baseUtils.isPC()) {
    return '';
  }
  // @ts-ignore
  const res: any = await bridge.call('requestAuthCodeFromCoreAuth', {
    clientID: params?.clientID || 'shopee-kredit',
  });

  insight.bridgeReport({
    name: 'requestAuthCodeFromCoreAuth',
    status: res?.errorCode === 0 ? 'success' : 'error',
    msg: `${res?.error}`,
  });

  if (res?.errorCode === 0) {
    return res.authCode;
  }
  return '';
};

/**
 * 尝试获取authCode，按time进行重试的次数。
 * @param time
 */
const getAuthCodeByRetryTimes = async (time: number) => {
  let retryTime: number = time <= 0 ? 1 : time;

  while (retryTime > 0) {
    --retryTime;
    // eslint-disable-next-line no-await-in-loop
    const authCode = await requestAuthCodeFromCoreAuth();
    if (authCode) {
      return authCode;
    }

    // 加200毫秒的延迟
    // eslint-disable-next-line no-await-in-loop
    await delay(200);
  }

  return '';
};

/**
 * 获取auth code
 */
export async function getAuthCode(): Promise<{
  errorCode: number;
  token: string;
  type: CodeType;
}> {
  const urlParams = baseUtils.queryParams();
  let nonceCode: string = urlParams.nonce;
  if (appInfo.nonceCode === nonceCode) {
    // url带了noncecode参数，只能用一次。
    nonceCode = '';
  }

  if (nonceCode) {
    appInfo.nonceCode = nonceCode;
    return {
      errorCode: 0,
      token: nonceCode,
      type: CodeType.OnceCode,
    };
  }

  if (urlParams?.state === 'fastescrow' && urlParams?.exit_state !== ExitState.Success) {
    // get wsa code fail。1告诉调用方，不用再重试，避免死循环
    // @ts-ignore
    bridge.callHandler('trackPerformanceEvent', {
      subtype: PerfEventType.getAuthCodeFail,
      payload: {
        fail_type: 1,
        err_code: urlParams?.exit_state,
      },
    });

    return {
      errorCode: 1,
      token: '',
      type: CodeType.OnceCode,
    };
  }

  // mock环境对authcode无要求，dev环境在PC模拟器上也没有要求
  if (REACT_APP_ENV === 'mock') {
    return {
      errorCode: 0,
      token: 'mock',
      type: CodeType.AuthCode,
    };
  }

  insight.loginReport({
    status: 'call',
    type: 'auth_code',
  });

  // 前两步处理从wsa网站重定向回来；下面的代码处理一进来的情况
  const authCode = await getAuthCodeByRetryTimes(3);

  // 如果为''，0告诉调用方，要用wsa url兜底
  return {
    errorCode: 0,
    token: authCode,
    type: CodeType.AuthCode,
  };
}

/**
 * 获取nonce code
 * @param timeout
 * @returns
 */
export async function getNonceCode(
  params: { productId?: string } = {},
  timeout = 5000,
): Promise<string> {
  const res = await bridge.promiseCallWithTimeout(
    IBridgeEvents.RequestLoginNonce,
    { ...params },
    timeout,
  );
  console.info('requestLoginNonce', res);

  insight.bridgeReport({
    name: IBridgeEvents.RequestLoginNonce,
    status: res?.error === 0 ? 'success' : 'error',
    msg: `${res?.error}`,
  });

  if (res?.error === 0) {
    console.log('use new web bridge get nonce code success');
    return res?.data?.nonce;
  }

  return '';
}

/**
 * 获取登录的code，优先判断url上是否有nonce code，如果有nonce code则直接返回，
 * 如果url上没有nonce code，则通过bridge获取nonce code
 * @returns
 */
export async function getLoginCode(): Promise<{
  errorCode: number;
  token: string;
  type: CodeType;
  headerKey: HeaderType;
}> {
  const urlParams = baseUtils.queryParams();
  const nonceCode: string = urlParams?.nonce as string;

  if (nonceCode) {
    // 获取nonce code 成功
    insight.loginReport({
      status: 'success',
      type: 'nonce_code',
    });

    return {
      errorCode: 0,
      token: nonceCode,
      type: CodeType.OnceCode,
      headerKey: HeaderType.NonceCode,
    };
  }

  if (urlParams?.state === 'fastescrow' && urlParams?.exit_state !== ExitState.Success) {
    // 获取nonce code 失败
    insight.loginReport({
      status: 'error',
      type: 'nonce_code',
    });

    // get wsa code fail。1告诉调用方，不用再重试，避免死循环
    return {
      errorCode: 1,
      token: '',
      type: CodeType.OnceCode,
      headerKey: HeaderType.NonceCode,
    };
  }

  // mock环境对authcode无要求，dev环境在PC模拟器上也没有要求
  if (REACT_APP_ENV === 'mock') {
    return {
      errorCode: 0,
      token: 'mock',
      type: CodeType.NonceCode,
      headerKey: HeaderType.NonceCode,
    };
  }

  const appVersion = common.getAppVersion();
  // 判断app版本是否大于3.37，如果小于这个版本则直接return出去做wsa兜底
  if (common.getAppType() === 1 && appVersion < 33700) {
    return {
      errorCode: 0,
      token: '',
      type: CodeType.NonceCode,
      headerKey: HeaderType.NonceCode,
    };
  }

  const insightReportType = 'web_bridge_nonce_code';

  // 记录用户请求nonce code
  insight.loginReport({
    status: 'call',
    type: insightReportType,
  });

  // 前两步处理从wsa网站重定向回来；下面的代码处理一进来的情况
  let loginCodeToken = '';
  loginCodeToken = await getNonceCode();
  console.log("getNonceCode result，if '', will use wsa", loginCodeToken);

  // 上报获取nonce code是否成功
  insight.loginReport({
    status: loginCodeToken ? 'success' : 'error',
    type: insightReportType,
  });

  // 如果为''，0告诉调用方，要用wsa url兜底
  return {
    errorCode: 0,
    token: loginCodeToken,
    type: CodeType.NonceCode,
    headerKey: HeaderType.NonceCode,
  };
}
